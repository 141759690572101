import { __decorate } from "tslib";
import DateRangePicker from "@/components/shared/DateRangePicker.vue";
import EventModule from "@/store/module/shared/EventModule";
import moment from "moment";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
let EventsResultsDownloadDialog = class EventsResultsDownloadDialog extends Vue {
    constructor() {
        super(...arguments);
        this.diapasone = {
            from: moment().startOf("year").format("YYYY-MM-DD"),
            to: moment().endOf("year").format("YYYY-MM-DD"),
        };
    }
    get showDialog() {
        return this.value;
    }
    set showDialog(value) {
        this.$emit("input", value);
    }
    onRangeUpdate(data) {
        this.diapasone = data;
    }
    async download() {
        await this.$store.dispatch(EventModule.types.actions.DOWNLOAD_RESULTS_REPORT, this.diapasone);
    }
};
__decorate([
    Prop({ default: false, type: Boolean })
], EventsResultsDownloadDialog.prototype, "value", void 0);
EventsResultsDownloadDialog = __decorate([
    Component({
        components: {
            DateRangePicker,
        },
    })
], EventsResultsDownloadDialog);
export default EventsResultsDownloadDialog;
