import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import AdminModule from "@/store/module/admin/AdminModule";
import ApproveDialog from "../../shared/Approve.vue";
import EventDialog from "../../shared/dialogs/Event.vue";
import EventsResultsDownloadDialog from "../dialogs/Event/EventsResultsDownloadDialog.vue";
let HeaderActions = class HeaderActions extends Vue {
    constructor() {
        super(...arguments);
        this.approve = {
            delete: false,
            depremoderate: false,
        };
        this.addEvent = {
            dialog: false,
        };
        this.eventsResultsDownload = {
            dialog: false,
        };
    }
    get isSuperAdmin() {
        return this.$store.getters[AdminModule.types.getters.IS_SUPER_ADMIN];
    }
    get isAdmin() {
        return !!this.$store.getters[AdminModule.types.getters.ADMIN];
    }
    removeEvents() { }
    get buttons() {
        return [
            {
                text: `Добавить событие`,
                icon: "mdi-tab-plus",
                action: () => {
                    this.addEvent.dialog = true;
                    //console.log(`addMemberDialog`, this.addMemberDialog)
                },
                css: "blue",
                showOnlyForSuperAdmin: false,
                show: this.isAdmin,
            },
            {
                text: `Удалить события`,
                icon: "mdi-tab-minus",
                action: () => {
                    this.approve.delete = !this.approve.delete;
                },
                css: "red darken-1",
                showOnlyForSuperAdmin: true,
                isHandleSelected: true,
                show: this.isAdmin,
            },
            {
                text: "Результаты",
                icon: "mdi-file-excel",
                action: () => {
                    this.eventsResultsDownload.dialog = !this.eventsResultsDownload.dialog;
                },
                css: "green",
                showOnlyForSuperAdmin: true,
                show: this.isSuperAdmin,
            },
        ];
    }
    depremoderate() {
        this.$emit("on-depremoderate");
    }
    mounted() { }
};
__decorate([
    Prop({ type: Number })
], HeaderActions.prototype, "category", void 0);
__decorate([
    Prop({ type: Array, default: [] })
], HeaderActions.prototype, "selectedEvents", void 0);
__decorate([
    Emit("on-remove")
], HeaderActions.prototype, "removeEvents", null);
HeaderActions = __decorate([
    Component({
        components: {
            ApproveDialog,
            EventDialog,
            EventsResultsDownloadDialog,
        },
    })
], HeaderActions);
export default HeaderActions;
